$sidebar-bg-color: #1c2136;
$sidebar-color: #ffffff !default;
$sidebar-width: 250px;
// $sidebar-collapsed-width: 0px !default;
$highlight-color: #753500;
$submenu-bg-color: #0d1423 !default;
$submenu-bg-color-collapsed: #0d1423 !default;
// $icon-bg-color: #af7f31 !default;
$icon-size: 20px;
// $breakpoint-xs: 480px !default;
// $breakpoint-sm: 576px !default;
// $breakpoint-md: 768px !default;
// $breakpoint-lg: 992px !default;
// $breakpoint-xl: 1200px !default;

@import "react-pro-sidebar/dist/scss/styles.scss";

// --------- Customize Pro sidebar ------------- //
.pro-sidebar {
  height: 100%;
  .pro-menu {
    padding-bottom: 0;

    // --------- Main menu ------------- //
    .pro-item-content {
      font-size: 14px;
    }
    .pro-inner-item {
      font-weight: 700;
      padding-left: 48px;
      & > .pro-arrow-wrapper .pro-arrow {
        border-color: #eea909 !important;
      }
      &:hover,
      &:focus {
        background-color: #eea909;
        color: #753500;
        font-weight: 700;

        .pro-icon-wrapper .pro-icon {
          color: #753500;
          background-color: #eea909;
        }

        > .pro-arrow-wrapper .pro-arrow {
          border-color: #753500 !important;
        }
      }
    }
    // --------- End main menu ------------- //

    // --------- Sub menu ------------- //
    .pro-sub-menu.open {
      background-color: #0d1423;
    }
    .pro-menu-item.pro-sub-menu .pro-inner-list-item {
      padding: 0;
      & > div > ul {
        padding: 0;
      }
      .pro-inner-item {
        padding-left: 48px;
        &:before {
          display: none;
        }
      }
    }
    // --------- End Sub menu ------------- //
  }

  & > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow: hidden;
    height: auto;
  }

  .pro-icon-wrapper .pro-icon {
    color: #eea909;
    background-color: #1c2136;
  }

  // --------- Active state ------------- //
  .active {
    background-color: #eea909;
    color: #753500;
    font-weight: 700 !important;

    .pro-icon-wrapper .pro-icon {
      color: #753500;
      background-color: #eea909;
    }
  }
  // --------- Active state ------------- //
}
