@import "../../../assets/scss/typography";
@import "../../../assets/scss/variables";

.todo-item {
  border: 1px solid;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 8px;

  &.info {
    color: $grey-800;
    background-color: $info-100;
    border-color: $info-400;
    .todo-content-icon {
      color: $info-500;
    }
  }
  &.danger {
    color: $warning-800;
    background-color: $warning-100;
    border-color: $warning-400;
    .todo-content-icon {
      color: $warning-500;
    }
  }
  &.warning {
    color: $warning-800;
    background-color: $warning-100;
    border-color: $warning-400;
    .todo-content-icon {
      color: $warning-500;
    }
  }
  &.primary {
    color: $grey-800;
    background-color: $grey-100;
    border-color: $grey-400;
    .todo-content-icon {
      color: $grey-500;
    }
  }

  .todo-content {
    padding: 0px;
    flex-direction: row;
    align-items: center;
    display: flex;
    position: relative;
  }

  .todo-content-icon {
    align-self: flex-start;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .todo-content-right {
    margin-left: auto;
  }

  .todo-content-left {
    margin-right: auto;
    margin-left: 5px;
  }

  .todo-title {
    @include heading-title-18-bold;
  }

  .link-btn {
    background-color: transparent;
    text-decoration: underline;
    border: none;
    font-weight: bold;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    &.btn-danger {
      color: $warning-800;
    }
    &.btn-info {
      color: $info-500;
    }
    &.btn-warning {
      color: $warning-800;
    }
  }
}
