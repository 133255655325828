@import '../../assets/scss/main.scss';

.multi-currency-form {
  .message {
    color: $grey-700;
    margin-bottom: 1rem;
  }
  .dual-list-title {
    gap: 120px;
  }
  .react-dual-listbox button {
    span {
      font-size: 8px;
      vertical-align: middle;
    }
  }
  .move-button-label {
    padding: 0 8px;
    @include body-small-bold;
  }
}
