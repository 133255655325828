@import "../../../assets//scss/variables.scss";

.svix-container {
  max-width: 100% !important;
  width: 100% !important;

  .svix-frame {
    max-width: 100%;
    width: 100%;
    height: calc(100vh - #{$navbar-header-height} - 5px);
    overflow: hidden;
    border: none;
  }
}
