@import "../../assets//scss//variables";

.example-col {
  margin-top: auto;
  margin-bottom: auto;
}

.example-text {
  font-size: 0.8rem;
}
