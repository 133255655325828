@import "../../assets/scss/status.scss";

.allocated-status {
  @extend .status-badge;
  font-weight: 400;
  color: $colour-white;
}

.waitingtobeallocated-status {
  @extend .allocated-status;
  background: $grey-500 !important;
}

.autoallocated-status,
.manuallyallocated-status {
  @extend .allocated-status;
  background: $green-400 !important;
}

.unallocated-status {
  @extend .allocated-status;
  background: $yellow-500 !important;
}

.notapplicable-status {
  @extend .allocated-status;
  background: $blue-400 !important;
}

.error-status {
  @extend .allocated-status;
  background: $red-400 !important;
}
