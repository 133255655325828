@import "../../assets/scss/_variables.scss";

.icon-button {
  margin-left: 5px;
  margin-right: 0px;
}

.icon-button-enabled {
  @extend .icon-button;
  color: $icon-link-color;
  &:hover {
    color: $icon-link-hover-color;
    cursor: pointer;
  }
}

.icon-button-disabled {
  @extend .icon-button;
  color: #bababa;
}
