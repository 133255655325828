@import "../../../assets/scss/variables";
@import "../../../assets/scss/typography";

.copy-access-key-dialog-container {
  .key-col{
    display: grid;

    .access-key-body {
      margin: 10px;
      border-radius: 4px;
      background: $info-200;
      color: $info-900;
      text-align: center;
      padding: 0 8px;
      height: 24px;
      display: inline-table;
    }
  }
}