@import "../../../assets/scss/_variables.scss";

.summary-table {
  width: auto;
  max-width: 350px;

  td {
    padding: 0.1rem !important;
  }

  .item-value {
    text-align: right;
  }
}

.results-containter {
  width: 100%;
  margin-top: 10px;
}

.results-table {
  tbody {
    font-size: 0.8rem;
  }
  tr.noBorder td {
    border: 0;
  }

  .import-status.duplicate {
    color: $yellow-500;
  }
  .import-status.notimported {
    color: $red-500;
  }

  .allocated-status.manuallyallocated {
    color: $green-500;
  }

  .allocated-status.autoallocated {
    color: $green-500;
  }

  .allocated-status.error {
    color: $red-500;
  }
}
