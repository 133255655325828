.button-saving {
  padding-left: 6px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  .spinner-container {
    height: 32px;
    width: 32px;
    filter: invert(100%) grayscale(100%);
  }
}

.button-saving-small {
  padding-left: 4px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  .spinner-container {
    height: 27px;
    width: 27px;
    filter: invert(100%) grayscale(100%);
  }
}
