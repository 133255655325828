.nav-tabs {
  border: none !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $grey-800 !important;
  border-bottom: 3px solid $warning-500 !important;
}

.nav-tabs .nav-link {
  background-color: transparent;
  color: $grey-700;
  height: 36px;
  line-height: 1rem;
  border-bottom: 3px solid !important;
  border-color: transparent transparent $grey-400 !important;
  @include body-bold;
}
