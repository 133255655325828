@import "../../assets//scss/status.scss";

.enabled-type {
  @extend .status-badge;
  font-weight: 400;
  color: $colour-white;
  padding: 1px 8px;
}

.enabled {
  @extend .enabled-type;
  background: $green-400;
}

.disabled {
  @extend .enabled-type;
  background: $red-400;
}
