.error-container {
  text-align: center;
  margin-top: 80px;
  font-size: 35px;
  img {
    width: 50%;
    height: 50%;
  }
  .error-message {
    font-size: 2.5rem;
    color: #6c757d;
  }
  .detail-message {
    font-size: 1.5rem;
    color: #909aa1;
  }
  .go-home {
    font-size: 1vw;
  }
}
