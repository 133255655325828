@import "../../../assets/scss/_variables.scss";

.settlement-expired {
  color: $grey-400;
}

.settlement-offer-container {
  flex-grow: 1;
}

.settlement-offer-menu {
  a {
    text-decoration: none;
    color: $icon-link-color;
  }
  a:hover {
    color: $icon-link-hover-color;
  }

  .dropdown-item {
    padding-left: 10px;
    font-size: 0.8125rem;
    color: $menu-text-color;
    &:hover,
    &:focus {
      background-color: $menu-background-colour-focus;
      color: inherit;
    }
    svg {
      margin-right: 10px;
    }
  }
  .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
}
