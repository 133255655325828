@import "../../../assets/scss/variables.scss";

.tab-title {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;

  .icon {
    color: $danger-500;
  }
}

.item-description {
  font-size: 0.9rem;
  color: $grey-800;
}
