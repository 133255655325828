.funding-container {
  max-width: 100% !important;
  width: 100% !important;
  background-color: #ffffff;

  .title {
    text-align: center;
    margin-left: 8px;
    margin-bottom: 4px;
  }

  .capitalise-frame {
    max-width: 100%;
    width: 100%;
    height: calc(100% - 30px);
    overflow: hidden;
  }
}
