.badge {
  color: $grey-800 !important;
  min-width: 15px;
  padding: 2px 5px !important;
  border-radius: 45% !important;

  &.tab-badge {
    background-color: $grey-400 !important;
  }
}
