@import "../assets/scss/variables";
@import "../assets/scss/typography";

.button-row {
  display: flex;
  justify-content: flex-start;
}

.tag-items {
  display: flex;

  .tag {
    margin-right: 4px;
  }
  .tag-end {
    margin-right: 0px;
  }
}

.item-view-section {
  .details-title {
    display: flex;
    justify-content: space-between;
  }

  .details-status {
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
  }

  .panel-column {
    margin-bottom: 10px;
  }

  .panel-item {
    padding: 5px;
  }
  .panel-item-title {
    font-weight: 500;
  }
  .panel-item-body {
    font-size: 1rem;
  }

  .panel-table {
    thead {
      tr {
        padding-bottom: 0px;
        font-weight: 500;
      }
      td {
        padding-top: 5px;
        padding-bottom: 0px;
        @include body-small;
      }
    }

    tbody {
      tr {
        padding-top: 0px;
        margin-bottom: 4px;
      }
      td {
        padding-top: 0px;
        padding-bottom: 4px;
      }
    }
  }
  .overdue {
    color: $red-500;
    font-weight: 700;
  }
}

.common-form {
  label.form-label {
    font-weight: bold;
  }
}
