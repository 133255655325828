@import "../../ProvidersCommon/Connect.scss";

.details-title {
  display: flex;
}
.tag-items {
  .tag-status {
    @include body-bold;
    font-weight: 700 !important;
    font-size: 0.8rem !important;
    text-align: center;
    margin-left: 10px;
    width: fit-content;
    height: min-content;
    border-radius: 4px;
    text-align: center;
    padding: 0 8px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .test-tag-status {
    background: $warning-500;
    color: $warning-900;
  }
  .legacy-tag-status {
    background: $red-400;
    color: $red-900;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
