@import '../../../assets/scss/main.scss';

.payment-provider-card-wrapper {
  max-width: 500px;
  max-height: 500px;
  overflow-y: auto;
  @include scroll-style-default;
  .payment-provider-card:first-child {
    border-radius: 4px 4px 0px 0px !important;
  }
  .payment-provider-card {
    border-radius: 0 !important;
    border-bottom: none;
  }
  .payment-provider-card:last-child {
    border-radius: 0px 0px 4px 4px !important;
    border-bottom: 1px solid $grey-400;
    margin-bottom: 1rem;
  }
}

.payment-provider-card {
  width: 100%;
  border-color: $grey-400;
  .card-img-top {
    height: 50px;
    width: fit-content;
  }
  .card-text {
    color: $grey-700 !important;
    @include body-small-italic;
  }
  .nav-link {
    color: $info-500;
    @include body-small-bold;
  }
}
