@import "../../assets/scss/typography";
@import "../../assets/scss/variables";

.custom-detail-card {
  min-height: "15rem";
  width: "100%";
  /* White */

  background: #ffffff;
  /* Grey/400 */

  border: 1px solid #d3ddee !important;
  /* Elevation/1px */

  box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14), 0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2);
  border-radius: 8px;

  .card-title {
    @include heading-title-18-bold;
  }

  .card-text {
    padding: 0 24px;
    @media screen and (max-width: $tablet-device-max-width) {
      padding: 0 8px;
    }
  }

  .card-body {
    padding: 32px;
    @media screen and (max-width: $tablet-device-max-width) {
      padding: 16px;
    }
  }

  .main-item {
    margin-bottom: 1.5rem;
    .title {
      color: $grey-700;
      @include body-small;
    }
    .content {
      @include heading-title-20-bold;
    }
    .clickable {
      cursor: pointer;
    }
  }

  a.item-url {
    color: $info-500;
    @include body-small-bold;
  }
}
