@import '../../../../assets/scss/status.scss';
@import '../../../../assets/scss/typography';

.custom-connect-page {
  hr {
    color: $grey-400;
  }

  button {
    font-weight: bold !important;
  }

  .custom-detail-card .card-title {
    font-size: 16px !important;
    margin-bottom: 16px;
  }

  label.form-label {
    font-weight: bold;
  }
}
