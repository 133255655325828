@import "../../assets/scss/status.scss";

.payment-transaction-status {
  @extend .status-badge;
  font-weight: 400;
  color: $colour-white;
}

.pending-status {
  @extend .payment-transaction-status;
  background: $blue-400 !important;
}

.processing-status {
  @extend .payment-transaction-status;
  background: $yellow-400 !important;
}

.paid-status {
  @extend .payment-transaction-status;
  background: $green-400 !important;
}

.failed-status,
.notauthorised-status,
.rejected-status,
.cancelled-status {
  @extend .payment-transaction-status;
  background: $red-400 !important;
}

.refunded-status {
  @extend .payment-transaction-status;
  background: $grey-500 !important;
}

.disputed-status,
.duplicated-status {
  @extend .payment-transaction-status;
  background: $yellow-500 !important;
}
