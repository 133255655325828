@import '../../assets/scss/variables';
@import '../../assets/scss/typography';

.payment-item {
  .payment-item-content {
    padding: 0px;
    flex-direction: row;
    align-items: center;
    display: flex;
    position: relative;
  }

  .content-right {
    margin-left: auto;
    text-align: right;
  }

  .content-left {
    margin-right: auto;
    margin-left: 5px;
  }

  .content-customer-name {
    @include font-bold;
  }

  .content-date-time {
    color: $grey-600;
    @include body-small;
  }

  .content-transaction {
    color: $green-400;
    @include body-large-bold;
  }

  .content-transaction-success {
    @extend .content-transaction;
    color: $green-400;
  }

  .content-transaction-failed {
    @extend .content-transaction;
    color: $red-400;
  }
}
