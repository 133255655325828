button {
  padding: 3.5px 2rem;
  white-space: nowrap;
}

button.btn.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:hover {
    background-color: $warning-500;
    border-color: $warning-500;
  }
}

button.btn.btn-secondary {
  background-color: white;
  color: $primary;
  border-color: $grey-400;
  &:hover {
    background-color: $grey-400;
    border-color: $grey-400;
  }
}
