@import "../../../assets/scss/main.scss";

.justify-row {
  display: flex;
  justify-content: space-between;
}

.modal-with-accordion {
  padding: 0 !important;
  margin: 0 !important;
}
