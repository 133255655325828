@import "../../../assets/scss/typography";
@import "../../../assets/scss/variables";

.clear-button {
  font-size: 0.7rem !important;
  margin-top: 0;
}

.editing-warning {
  color: $danger-500;
  @include body-small-bold;
}
