@import "../../assets/scss/status.scss";
@import "../../assets/scss/typography";

.payment-request-status {
  @extend .status-badge;
  border-radius: 4px;
  color: $colour-white;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.small-size {
    padding: 0 8px;
    height: 17px;
    line-height: 11px;
    @include body-small-bold;
  }

  &.medium-size {
    height: 24px;
    @include body-small;
  }
}

.open-status {
  @extend .payment-request-status;
  background: $info-500;
}

.pending-status {
  @extend .payment-request-status;
  background: $blue-400;
}

.completed-status,
.partcompleted-status,
.fullypaid-status {
  @extend .payment-request-status;
  background: $success-600;
}

.cancelled-status,
.unpaid-status {
  @extend .payment-request-status;
  background: $red-400;
}

.expired-status,
.partpaid-status {
  @extend .payment-request-status;
  background: $warning-500;
}
