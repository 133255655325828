.breadcrumb {
  color: $info-500;
  align-items: center;
  gap: 4px;
  svg {
    width: 10px;
    height: 10px;
  }
  * > a {
    color: $info-500;
    @include body-small;
  }
}
