.initials-icon-container {
  display: inline-block;
  span {
    float: left;
  }
}
.users-details-container {
  display: inline-block;
  position: relative;
  padding: 5px 5px 5px 10px;
  p {
    margin: 0;
  }
}
