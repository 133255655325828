@import "../../assets/scss/typography";
@import "../../assets/scss/variables";

.currency-item {
  .currency-text {
    padding-right: 4px;
    @include body-extra-small;
  }

  &.paid,
  &.completed,
  &.fullyPaid {
    color: $success-600;
    .currency-value {
      line-height: 1.2em;
      @include heading-title-28-bold;
    }
    .currency-text {
      @include body-small-bold;
    }
  }

  &.pending {
    color: $blue-400;
    .currency-value {
      line-height: 1.2em;
      @include heading-title-28-bold;
    }
    .currency-text {
      @include body-small-bold;
    }
  }

  &.duplicated,
  &.notauthorised,
  &.cancelled,
  &.partpaid {
    color: $danger-600;
    .currency-value {
      line-height: 1.2em;
      @include heading-title-28-bold;
    }
    .currency-text {
      @include body-small-bold;
    }
  }

  &.processing,
  &.disputed,
  &.refunded,
  &.open {
    color: $info-500;
    .currency-value {
      line-height: 1.2em;
      @include heading-title-28-bold;
    }
    .currency-text {
      @include body-small-bold;
    }
  }

  &.rejected,
  &.failed,
  &.expired,
  &.unpaid {
    color: $danger-500;
    .currency-value {
      line-height: 1.2em;
      @include heading-title-28-bold;
    }
    .currency-text {
      @include body-small-bold;
    }
  }
}
