$default-font-family: OpenSans,
  // Safari for OS X and iOS (San Francisco)
  -apple-system,
  // Chrome < 56 for OS X (San Francisco)
  BlinkMacSystemFont,
  // Windows
  'Segoe UI',
  // Android
  'Roboto',
  // Basic web fallback
  'Helvetica Neue',
  Arial, sans-serif,
  // Emoji fonts
  'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$colour-black: #0f172a;
$colour-grey-darkest: #475569;
$colour-grey-dark: #94a3b8;
$colour-grey: #cdd5e1;
$colour-grey-light: #e2e8f0;
$colour-grey-lightest: #f1f5f9;
$colour-white: #ffffff;

$grey-100: #f5f7fb;
$grey-200: #eff3fa;
$grey-300: #e3eaf7;
$grey-400: #d3ddee;
$grey-500: #b2bfd2;
$grey-600: #8b98ad;
$grey-700: #656e85;
$grey-800: #42495e;
$grey-900: #1c2136;

$success-100: #eafde8;
$success-200: #b8f9b9;
$success-300: #76ec82;
$success-400: #51d96d;
$success-500: #20c151;
$success-600: #17a550;
$success-700: #108a4d;
$success-800: #0a6f47;
$success-900: #065c42;

$danger-100: #fde7e7;
$danger-200: #f3caca;
$danger-300: #eaa4a4;
$danger-400: #da6060;
$danger-500: #c91b1b;
$danger-600: #b80000;
$danger-700: #a60000;
$danger-800: #860000;
$danger-900: #5b0000;

$yellow-100: #fefaec;
$yellow-200: #feecb9;
$yellow-300: #fdcf65;
$yellow-400: #fbbc44;
$yellow-500: #f99a00;
$yellow-600: #d67c00;
$yellow-700: #b36000;
$yellow-800: #904800;
$yellow-900: #773700;

$green-100: #e7f8e9;
$green-200: #b0f1bd;
$green-300: #70d78f;
$green-400: #2fb063;
$green-500: #037c40;
$green-600: #026a41;
$green-700: #01593f;
$green-800: #00473a;
$green-900: #003b35;

$blue-100: #f6fcfd;
$blue-200: #b5e6f9;
$blue-300: #7bc5ec;
$blue-400: #3a9bd9;
$blue-500: #0354a5;
$blue-600: #023f8a;
$blue-700: #012c6f;
$blue-800: #001f5c;
$blue-900: #001f5c;

$red-100: #fdf1eb;
$red-200: #fbc5b7;
$red-300: #f48d7f;
$red-400: #eb443f;
$red-500: #dd1f2b;
$red-600: #be1630;
$red-700: #9f0f32;
$red-800: #800931;
$red-900: #6a0530;

$info-100: #f0f8fd;
$info-200: #d4eafc;
$info-300: #a0cdfa;
$info-400: #5c9cef;
$info-500: #1f6beb;
$info-600: #1b55d6;
$info-700: #153eb9;
$info-800: #112a9c;
$info-900: #0c1a7e;

$warning-100: #fbf6dc;
$warning-200: #faedb7;
$warning-300: #f6dd8a;
$warning-400: #f0c346;
$warning-500: #eea909;
$warning-600: #d18500;
$warning-700: #b56800;
$warning-800: #984e00;
$warning-900: #753500;

$primary: #42495e;
$font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size: 14px;
$navbar-dark-color: #fff;
$menu-text-color: #6a6c6f;
$menu-background-colour-focus: #e9ecef !important;
$body-bg: #fbfbfb;
$body-color: #333333;

$icon-link-color: $info-500;
$icon-link-hover-color: $info-400;

$control-background-color: #ffffff;
$control-border-colour: #e5e5e5;

// User
$modal-header-bg: #ffffff;
$modal-body-bg: #fbfbfb;
$modal-footer-bg: #fefefe;

$navbar-header-height: 55px;

$default-margin: 15px;
$default-margin-bottom: 15px;

// Based on hi-res tablet like iPad Air
$tablet-device-max-width: 844px;

//$modal-content-color: $primary;
// $font-family-sans-serif: "Poppins", sans-serif;
// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

// $utilities: (
//   "custom-margin-top": (
//     property: margin-top,
//     class: mt,
//     values: (
//       0: 0,
//       1: 0.4rem,
//       2: 0.8rem,
//       3: 1.2rem,
//       4: 1.6rem,
//       5: 2rem,
//     ),
//   ),
// );
