@import "../../assets/scss/status.scss";

.payment-transaction-type {
  @extend .status-badge;
  border-radius: 4px;
  color: $colour-white;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.small-size {
    padding: 0 8px;
    height: 16px;
    @include body-small-bold;
  }

  &.medium-size {
    height: 24px;
    @include body-small;
  }
}

.payment-type {
  @extend .payment-transaction-type;
  background: $info-500;
}

.refund-type {
  @extend .payment-transaction-type;
  background: $red-400;
}
