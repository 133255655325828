@import "./_variables.scss";
@import "./typography";

.status-badge {
  width: fit-content;
  height: min-content;
  border-radius: 4px;
  background: $grey-200;
  text-align: center;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-badge-warning {
  @include body-bold;
  width: fit-content;
  height: min-content;
  border-radius: 4px;
  background: $warning-500;
  color: $warning-900;
  text-align: center;
  padding: 0 8px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
