@import "../../assets/scss/variables";
@import "../../assets/scss/typography";

.panel-column {
  margin-bottom: 10px;
}
.panel-item {
  display: flex;
  flex-grow: 3;
}
.panel-item-title {
  width: 180px;
  color: $grey-700;
  font-weight: 500;
}
.panel-item-body {
  flex: 2;
  font-weight: 500;
  a {
    color: $info-500;
  }
}
.overdue {
  color: $red-500;
}
