@import '../assets//scss//variables.scss';

.main-page {
  min-height: calc(100vh - #{$navbar-header-height});
  height: 100%;
  background: $grey-100;
}

.view-area {
  margin-top: 10px;
  margin-left: 10px;
}
