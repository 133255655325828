@import "../../assets/scss/main.scss";

// We only set the height of the nav header when the menu doesn't collapse
// otherwise the collapsable menu goes transparent and has other issues
@media (min-width: 768px) {
  .top-nav-header {
    height: $navbar-header-height;
  }
}

.top-nav-header {
  background-image: none;
  background-color: #efa31f;
  position: relative;

  .header-logo {
    height: $navbar-header-height;
    margin-left: 15px;
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .navbar-toggler {
    margin-right: 15px;
  }

  .navbar-nav {
    .nav-link {
      display: flex;
      align-items: center;
      height: $navbar-header-height;
      color: rgba(#fff, 0.55);
      padding: 10px;
      font-size: 1rem;
      color: $menu-text-color;
      &:hover,
      &:focus {
        background-color: #b87e1c;
        color: inherit;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
  .nav-divider::after {
    content: "|";
    float: right;
    color: #fff;
    line-height: 100%;
    font-size: 30px;
    padding-bottom: 10px;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
  .dropdown-item {
    padding: 10px;
    font-size: 0.8125rem;
    color: $menu-text-color;
    &:hover,
    &:focus {
      background-color: $menu-background-colour-focus;
      color: inherit;
    }
    svg {
      margin-right: 10px;
    }
  }
  .dropdown-item-highlight {
    color: $menu-text-color;
    font-weight: 600;
    background-color: #ffffff !important;
    &:hover,
    &:focus {
      background-color: #ffffff !important;
      cursor: default;
      color: $menu-text-color;
    }
  }
  .dropdown-header {
    font-size: $default-font-size;
  }

  .dataset-dropdown-title {
    font-weight: 600;
  }

  .dataset-dropdown-header-nonselectable {
    padding-left: 10px;
  }

  .dataset-dropdown-header-disabled {
    font-weight: 400;
    color: $menu-text-color;
    opacity: 0.5;
  }
}

.dataset-dropdown-item {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
