@import "../../assets/scss/_variables.scss";

.settlement-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.settlement-select-default {
  color: $grey-500;
  background-color: transparent;
  text-decoration: underline;
  border: none;
  padding: 1px 8px;
  display: inline;
  margin: 0;
  &:hover {
    color: $grey-700;
    cursor: pointer;
  }
}
