@import "../../assets/scss/_variables.scss";

img {
  .company-logo {
    padding: 10px;
    max-width: 600px;
    border: 5px solid #555;
  }
}

.company-logo {
  padding: 10px;
  max-width: 500px;
  border: 1px solid $grey-200;
  margin-bottom: $default-margin-bottom;
}

.no-logo {
  font-size: 1rem;
  border: 1px solid $grey-200;
  width: 200px;
}
