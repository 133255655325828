@import '../../../assets/scss/status.scss';

.default-payment-proivder {
  @extend .status-badge;
  font-weight: 400;
  font-size: 0.8rem;
  color: $colour-white;
  background: $green-400;
}

.provider-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.provider-spacer-left {
  margin-left: 4px;
}

.provider-select-default {
  font-size: 14px;
  color: $info-500;
  text-decoration: underline;

  padding: 1px 8px;

  &:hover {
    color: $info-700;
    cursor: pointer;
  }
}
