@import "../../assets/scss/typography";

.tag-details {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;

  &.small-size {
    padding: 0 8px;
    height: 16px;
    @include body-small-bold;
    display: flex;
    align-items: center;
  }

  &.medium-size {
    @include body-small;
    height: 24px;
    display: flex;
    align-items: center;
  }

  &.small-size {
    padding: 0 8px;
    height: 16px;
    @include body-small-bold;
  }
}
