@import "../../../assets/scss/typography";
@import "../../../assets/scss/variables";

.payment-allocation-type {
  &.closed {
    color: $success-600;
  }
  &.processing {
    color: $info-500;
  }
  &.reserved {
    color: $info-500;
  }
  &.scheduled {
    color: $info-500;
  }
}

.payment-allocation-status {
  &.allocated {
    color: $success-600;
  }
  &.provisional {
    color: $info-500;
  }
  &.cancelled {
    color: $danger-600;
  }
}
