.user-initials {
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 4px 4px 4px;
  width: 30px;
  height: 30px;
  font-size: 13px;
  background-color: #b30e00;
  color: #fff;
}
