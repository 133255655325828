@import "../../assets/scss/typography";
@import "../../assets/scss/variables";

.loading-container {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0; /* Adjust z-index to ensure it appears above other elements */

  align-items: center;
  flex-direction: column;
  margin-top: 100px;

  .loading-message {
    @include heading-title-28;
    color: $primary;
  }
}
