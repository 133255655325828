@import "../../../../assets/scss/main.scss";

.filter-dialog {
  max-width: 550px;
  .react-dual-listbox button,
  .react-dual-listbox select {
    border-color: $grey-400;
    @include scroll-style-default;
  }
}
