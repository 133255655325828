.pdf-view {
  .pdf-document-view {
    width: 100%;
    height: 75vh;
  }
  .loading-document {
    padding: 50px;
    width: 100%;
    height: 100%;
  }
}
