@import "../../assets/scss/status.scss";

.transaction-type {
  @extend .status-badge;
  border-radius: 4px;
  color: $colour-white;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.small-size {
    padding: 0 8px;
    height: 16px;
    @include body-small-bold;
  }

  &.medium-size {
    height: 24px;
    @include body-small;
  }
}

.none-type {
  @extend .transaction-type;
  background: $info-400;
}

.invoice-type {
  @extend .transaction-type;
  background: $info-700;
}

.receipt-type {
  @extend .transaction-type;
  background: $info-700;
}

.creditnote-type {
  @extend .transaction-type;
  background: $red-300;
}

.payment-status {
  @extend .transaction-type;
  background: $info-700;
}

.balanceincreasingtransaction-type,
.balancereducingtransaction-type,
.quotation-type,
.proformainvoice-type,
.orderacknowledgement,
.goodsdespatchnote {
  @extend .transaction-type;
  background: $info-700;
}
