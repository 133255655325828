@import "../../../../assets/scss/typography";
@import "../../../../assets/scss/variables";

div .warning {
  color: $red-500;
}

.example-result {
  .title {
    font-size: 0.9rem;
    color: $grey-700;
  }
  .body {
    margin-top: 5px;
    padding: 7px 10px 7px 10px;
    font-size: 0.9rem;

    border-radius: 4px;
    color: $grey-700;
    border: 1px solid $grey-400;
  }
}
.multiline {
  white-space: pre-line;
}
