@import "../../assets/scss/status.scss";

.paid-status {
  @extend .status-badge;
  color: $colour-white;
}

.fullypaid-status {
  @extend .paid-status;
  background: $success-600;
}

.partpaid-status {
  @extend .paid-status;
  background: $warning-400;
}

.unpaid-status {
  @extend .paid-status;
  background: $red-400;
}
