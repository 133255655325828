* {
  font-family: $default-font-family;
}

button,
a {
  font-family: $default-font-family;
  font-size: $default-font-size !important;
  outline: none !important;
  box-shadow: none !important;
}

html,
body {
  font-size: 14px;
}

body {
  background-color: #f5f5f5;
  :root {
    --bs-secondary-rgb: #d3ddee;
  }
}

#wrapper {
  min-height: calc(100vh - #{$navbar-header-height});
  background-color: transparent;
}

.form-control:focus,
input[type="text"] {
  &:hover,
  &:active,
  &:focus,
  &:visited,
  &:focus-visible,
  &:focus-within,
  &:target,
  &:active:hover {
    outline: none !important;
    box-shadow: none !important;
  }
}

.form-check.form-switch {
  margin-top: 4px;
  input.form-check-input {
    margin-top: 5px;
  }
}

@mixin scroll-style-default {
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #a9bddc;
  }
}

// -------------- //
//    Button      //
// -------------- //

button.btn.btn-link {
  text-decoration: none;
  color: $info-500;
  font-weight: bold;
}

.dropdown-toggle::after {
  margin-left: 0.55em !important;
  vertical-align: 0.18em !important;
}

.card {
  border-radius: 8px !important;
}
