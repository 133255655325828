@import "../../../assets/scss/main.scss";

.reset-warning {
  color: $red-500;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
}

.reset-confirm {
  color: $red-500;
  text-align: center;
  padding-top: 8px;
  font-weight: 700;
}

.demo-warning {
  @include body-small;
  color: $red-500;
}
