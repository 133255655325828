@import "../../../../assets/scss/status.scss";
@import "../../../../assets/scss/typography";

.common-badge {
  @extend .status-badge;
  border-radius: 4px;
  color: $grey-700;
  font-weight: bold;
  border: 1px solid $grey-400;
  background-color: $grey-100;

  &.small-size {
    padding: 0 8px;
    @include body-small;
  }

  &.medium-size {
    padding: 2px 8px;
    @include body-regular;
  }
}
