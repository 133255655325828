/*** Start of modal contols ***/
.modal {
  .modal-header {
    padding: 16px 24px;
    background-color: $modal-header-bg;
    .modal-title {
      @include heading-title-18-bold;
    }
  }

  .modal-body {
    background-color: $modal-body-bg;
  }

  .modal-footer {
    background-color: $modal-footer-bg;
  }

  .dialog-form {
    padding: 0 24px;

    .dialog-card {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      margin-top: calc(-1 * var(--bs-gutter-y));
      margin-right: calc(-0.5 * var(--bs-gutter-x));
      margin-left: calc(-0.5 * var(--bs-gutter-x));
      border-radius: 4px;
      background-color: $grey-100;
      border: 1px solid $grey-400;
      padding: 1rem;
      margin-bottom: 1rem;
    }

    // Blue style background
    .dialog-row {
      border-radius: 4px;
      background-color: $grey-100;
      border: 1px solid $grey-400;
      padding: 1rem;
      margin-bottom: 1rem;
    }
    // Last row option
    .last {
      margin-bottom: 0 !important;
    }
    .optional {
      color: $grey-600;
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
  }
}
