@import "../../../assets/scss/typography";
@import "../../../assets/scss/variables";

.table-filters-box {
  @include body-small;

  table {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .filters-disabled {
    color: $grey-500;
  }
}
